import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import PageTitle from '../components/PageTitle'
import PageWrapper from '../components/PageWrapper'

const PostTitle = styled.h2`
  font-weight: 300;
  font-size: 1.3rem;
  margin: 0;
  position: relative;
  flex-basis: 80%;
  @media screen and (max-width: 30em) {
    padding: 0.5em 0;
  }
`

const Post = styled.article`
  padding-left: var(--pad5);
  padding-right: var(--pad2);
  padding-top: var(--pad2);
  padding-bottom: var(--pad2);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 3rem;
    top: var(--pad2);
    bottom: 0;
    background: var(--primeColor);
    width: 1px;
    display: none;
  }
  @media screen and (max-width: 30rem) {
    display: flex;
    flex-flow: row wrap;
  }
`

const PostDate = styled.time`
  display: block;
  width: 2rem;
  padding: 0.5rem;
  padding-left: 1em;
  top: var(--pad2);
  left: 0;
  position: absolute;
  border: solid 1px transparent;
  
  @media screen and (max-width: 30em) {
    top: 0;
    right: 0;
    left: unset;
    width: unset;
    flex-basis: 15%;
    border-top: none;
    border-right: none;
  box-sizing: border-box;
  padding: 0.5em 1em;
  text-align: center;
  }
`

const Arrow = styled.span`
  color: var(--bgColor);
  background-color: var(--offPrimeGray);
  display: inline-block;
  padding: 2px;
  margin-left: 1rem;
  font-size: 1.3rem;
  line-height: 1;
  padding: 0 0.2em 0.3em;
`

const InternalLInk = styled(Link)`
  text-decoration: none;
  display: block;
  width: 100%;
  border-top: solid 1px var(--offPrimeGray);
  &:hover,
  &:focus {
    outline: solid 2px var(--primeColor);
    ${Post} {
      &:after {
      }

      ${PostTitle} {
        color: var(--primeColor);
      }
      p:after {
        color: var(--primeColor);
        background-color: var(--bgColor);
      }
      ${PostDate} {
        border-color: var(--primeColor);
        color: var(--primeColor);
      }
    }
  }
`

const ExternalLink = styled.a`
  text-decoration: none;
  display: block;
  border-top: solid 1px var(--offPrimeGray);
  &:hover,
  &:focus {
    outline: solid 2px var(--primeColor);
    ${Post} {
      &:after {
      }

      ${PostTitle} {
        color: var(--primeColor);
      }
      p:after {
        color: var(--primeColor);
        background-color: var(--bgColor);
      }
      ${PostDate} {
        border-color: var(--primeColor);
        color: var(--primeColor);
      }
    }
  }
`

const ExternalLinkDescription = styled.p`
  max-width: 36rem;
  text-decoration: none;
  margin-top: 1em;
  display: inline-block;
  color: var(--offPrimeGray);
  &:after {
    content: '\u21D7';
    color: var(--bgColor);
    background-color: var(--offPrimeGray);
    display: inline-block;
    padding: 2px;
    margin-left: 1rem;
    font-size: 1.3rem;
    line-height: 1;
    padding: 0 0.2em 0.3em;
  }
`

const Excerpt = styled.p`
  max-width: 36rem;
  text-decoration: none;
  color: var(--primeGray);
  margin-top: 1em;
  display: inline-block;
  flex-basis: 100%;
  &:last-child:after {
    content: '\u21D2';
    color: var(--bgColor);
    background-color: var(--offPrimeGray);
    display: inline-block;
    padding: 2px;
    margin-left: 1rem;
    font-size: 1.3rem;
    line-height: 1;
    padding: 0 0.2em 0.3em;
  }
`

const Month = styled.span`
  font-size: 1rem;
`

const Day = styled.span`
  font-size: 1.5rem;
`

const LinkAll = ({ to, subType, ...props }) =>
  subType !== 'externalLink' ? (
    <InternalLInk to={to} {...props} />
  ) : (
    <ExternalLink noopener href={to} target="_blank" {...props} />
  )

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Blog"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <PageWrapper vOnly>
          <PageTitle padded>Blog</PageTitle>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            const dateArr = node.frontmatter.date.split(' ')
            return (
              <LinkAll
                subType={node.frontmatter.subType || node.frontmatter.postType}
                to={node.fields.postPath}
                key={node.fields.slug}
              >
                <Post>
                  <PostTitle>{title}</PostTitle>
                  <PostDate>
                    <Month>{dateArr[0]}</Month>
                    <br />
                    <Day>{dateArr[1]}</Day>
                  </PostDate>
                  <Excerpt
                    dangerouslySetInnerHTML={{
                      __html: node.excerpt,
                    }}
                  />
                  {node.frontmatter.externalLinkDesc && (
                    <ExternalLinkDescription>
                      {node.frontmatter.externalLinkDesc}
                    </ExternalLinkDescription>
                  )}
                </Post>
              </LinkAll>
            )
          })}
        </PageWrapper>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { postType: { eq: "blog" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 360)
          fields {
            slug
            postPath
          }
          frontmatter {
            date(formatString: "MMM DD")
            title
            postType
            subType
            externalLinkDesc
          }
        }
      }
    }
  }
`
